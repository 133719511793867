'use client';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Switch } from '../../../lib/ui/switch';

export type InventorySource = {
    id: number;
    emailSourceId: number | null;
    sourceName: string;
    fileType: string;
    fromEmail: string;
    expectedAttachmentCount: number | null;
    subjectValue: string;
    attachmentName: string;
    itemNumberColumnName: string;
    qtyColumnName: string;
    minItemsToBeActive: number | null;
    activeStatus: boolean;
    deletedStatus: boolean;
};

export const columns: ColumnDef<InventorySource>[] = [
    {
        header: 'Active',
        cell: ({ row }) => (
            <Switch
                checked={row.original.activeStatus}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Set Active Status"
            />
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: 'sourceName',
        header: 'Source Name'
    },
    {
        accessorKey: 'fileType',
        header: 'File Type'
    },
    {
        accessorKey: 'fromEmail',
        header: 'From Email'
    },
    {
        accessorKey: 'expectedAttachmentCount',
        header: 'Expected Attachment Count'
    },
    {
        accessorKey: 'subjectValue',
        header: 'Subject Value'
    },
    {
        accessorKey: 'attachmentName',
        header: 'Attachment Name'
    },
    {
        accessorKey: 'itemNumberColumnName',
        header: 'Item Number Column Name'
    },
    {
        accessorKey: 'qtyColumnName',
        header: 'Quantity Column Name'
    },
    {
        accessorKey: 'minItemsToBeActive',
        header: 'Min Items To Be Active'
    },
    {
        id: 'actions',
    }
];
