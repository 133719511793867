'use client'
import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'



export type User = {
    userId: string
    username: string
    email: string
    firstName: string
    lastName: string
    lastLogin: string
    createdOn: string
    isAdmin: boolean
    sendNotificationsToEmail: boolean
    activeStatus: boolean
}

const formatDateTime = (dateString) => {
    if (!dateString) return '';
    const utcDate = new Date(dateString);
    return format(utcDate, 'PPpp');
};

export const columns: ColumnDef<User>[] = [

    {
        accessorKey: 'userId',
        header: 'User Id'
    },
    {
        accessorKey: 'username',
        header: 'Username'
    },
    {
        accessorKey: 'email',
        header: 'Email'
    },
    {
        accessorKey: 'firstName',
        header: 'First Name'
    },
    {
        accessorKey: 'lastName',
        header: 'Last Name'
    },
    {
        accessorKey: 'lastLogin',
        header: 'Last Login',
        cell: ({ row }) => formatDateTime(row.original.lastLogin)
    },
    {
        accessorKey: 'createdOn',
        header: 'Created On',
        cell: ({ row }) => formatDateTime(row.original.createdOn)
    },
    {
        accessorKey: 'isAdmin',
        header: 'Admin'
    },
    {
        accessorKey: 'sendNotificationsToEmail',
        header: 'Notifications'
    },
    {
        accessorKey: 'activeStatus',
        header: 'Active'
    },
    {
        id: 'actions',
    }
]