import {
    ChevronLeftIcon,
    ChevronRightIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon,
} from "@radix-ui/react-icons"
import { Table } from "@tanstack/react-table"
import React from "react"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "./select"
import { Button } from "./button"

interface DataTablePaginationProps<TData> {
    table: Table<TData>
    currentPage: number
    pagesCount: number
    setPage: (page: number) => void
    rowsPerPage: number
    setRowsPerPage: (perPage: number) => void
    totalRecords: number
}

export function DataTablePagination<TData>({
    table,
    currentPage,
    pagesCount,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRecords
}: DataTablePaginationProps<TData>) {
    return (
        <div className="flex items-center justify-between px-2 py-3">
            <div className="flex-1 text-sm text-muted-foreground">
                {/* Update to display the current page and total pages */}
                {/*<div> Page {(pagesCount === 0 ? 0 : currentPage)} of {pagesCount} </div>*/}
                <div> Page {currentPage} of {(pagesCount === 0 ? 1 : pagesCount)} </div>
                <div> Records {totalRecords}</div>
            </div>
            <div className="flex items-center space-x-6 lg:space-x-8">
                <div className="flex items-center space-x-2 mx-3">
                    <p className="text-sm font-medium">Rows per page</p>
                    <Select
                        value={`${rowsPerPage}`}
                        onValueChange={(value) => {
                            setRowsPerPage(Number(value))
                            setPage(1) // Always go to the first page when changing rows per page
                        }}
                    >
                        <SelectTrigger className="h-8 w-[80px]">
                            <SelectValue placeholder={String(rowsPerPage)} />
                        </SelectTrigger>
                        <SelectContent side="top">
                            {[10, 25, 50, 100, 250].map((pageSize) => (
                                <SelectItem key={pageSize} value={`${pageSize}`}>
                                    {pageSize}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <Button
                    variant="outline"
                    className="h-8 w-8 p-0 mx-2"
                    onClick={() => setPage(1)}
                    disabled={currentPage === 1}
                >
                    <span className="sr-only">Go to first page</span>
                    <DoubleArrowLeftIcon className="h-4 w-4" />
                </Button>
                
                <Button
                    variant="outline"
                    className="h-8 w-8 p-0 mx-2"
                    onClick={() => setPage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    <span className="sr-only">Go to previous page</span>
                    <ChevronLeftIcon className="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    className="h-8 w-8 p-0 mx-2"
                    onClick={() => setPage(currentPage + 1)}
                    disabled={currentPage === pagesCount}
                >
                    <span className="sr-only">Go to next page</span>
                    <ChevronRightIcon className="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    className="h-8 w-8 p-0 mx-2"
                    onClick={() => setPage(pagesCount)}
                    disabled={currentPage === pagesCount}
                >
                    <span className="sr-only">Go to last page</span>
                    <DoubleArrowRightIcon className="h-4 w-4" />
                </Button>
            </div>
        </div>
    )
}
