'use client'
import React from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { Switch } from '../../../lib/ui/switch'

export type Source = {
    id: string
    activeStatus: boolean
    vendorNameFromFile: string
    submitEmails: string
    vendorWantsTrimmedItemDescription: string
    vendorWantsNonDailySubmission: string
    vendorWantsAllAlignedLeft: string
    vendorWantsPDF: string
    vendorWantsAllData: string
}

export const columns: ColumnDef<Source>[] = [
    {
        header: 'Active',
        cell: ({ row }) => (
            <Switch
                checked={row.original.activeStatus}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Set Active Status"
            />
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: 'vendorNameFromFile',
        header: 'Vendor Name'
    },
    {
        accessorKey: 'submitEmails',
        header: 'Eamils to'
    },
    {
        accessorKey: 'vendorWantsTrimmedItemDescription',
        header: 'Trimmed Details'
    },
    {
        accessorKey: 'vendorWantsNonDailySubmission',
        header: 'Non Daily Submission'
    },
    {
        accessorKey: 'vendorWantsPDF',
        header: 'Wants PDF'
    },
    {
        accessorKey: 'vendorWantsAllData',
        header: 'Wants all data'
    },
    {
        id: 'actions',
    }
]