import React, { Component } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './styles/global.css';
import { ThemeProvider } from "./components/lib/ui/theme-provider"
import { Toaster } from "./components/lib/ui/toaster"
import NavMenu from './components/NavMenu';


function LayoutWithNavMenu() {
    const location = useLocation();

    // Check if the current location matches the login route
    const isNotLoginScreen = location.pathname !== '/';

    return (<>{isNotLoginScreen && <NavMenu />} {/* Display NavMenu on any screen besides the login screen */}
        <Layout>
            <Routes>
                {AppRoutes.map((route, index) => {
                    const { element, ...rest } = route;
                    return <Route key={index} {...rest} element={element} />;
                })}
            </Routes>

        </Layout>
    </>
    );
}
export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
                <LayoutWithNavMenu />
                <Toaster />
            </ThemeProvider>
        );
    }
}
