import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, CardFooter } from '../../../lib/ui/card';
import { Button } from '../../../lib/ui/button';
import axiosInstance from '../../../../axiosInterceptor';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Textarea } from '../../../lib/ui/textarea';
import BackButton from '../../../lib/ui/back-button';

interface TrackingNumberModel {
    id: number;
    orderTrackingDataId: number;
    trackingNumber: string;
    activeStatus: boolean;
    deletedStatus: boolean;
}

interface OrderTrackingModel {
    id: number;
    orderTrackingSourceId: number;
    accountNumber?: string;
    brandName?: string;
    customerName?: string;
    customerNumber?: string;
    customerPoNumber?: string;
    documentNumber?: string;
    estimatedArrivalDate?: string;
    itemNumber?: string;
    itemStatus?: string;
    lineStatus?: string;
    notes?: string;
    orderDate?: string;
    poDate?: string;
    qty?: number;
    qtyFulfilled?: number;
    shipToName?: string;
    shippedDate?: string;
    supplierName?: string;
    supplierOrderStatus?: string;
    wildcard1?: string;
    wildcard2?: string;
    trackingNumbers: TrackingNumberModel[];
    lastUpdated: string;
    activeStatus: boolean;
    deletedStatus: boolean;
}

export function OrderTrackingDetails() {
    const { orderId } = useParams();
    const [orderTracking, setOrderTracking] = useState<OrderTrackingModel | null>(null);
    const [note, setNote] = useState<string | undefined>('');
    const [isSaved, setIsSaved] = useState<boolean>(false); // New state variable for animation

    const formatDateTime = (dateString: string | undefined) => {
        if (!dateString) return 'N/A';
        const utcDate = new Date(dateString);
        return format(utcDate, 'PPpp');
    };

    const camelCaseToTitle = (str: string) => {
        return str
            .replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .replace(/^./, (char) => char.toUpperCase()); // Capitalize the first letter
    };

    useEffect(() => {
        axiosInstance.get(`/api/order-tracking/order/${orderId}`)
            .then(response => {
                setOrderTracking(response.data);
                setNote(response.data.notes);
            })
            .catch(error => {
                console.error('There was an error fetching the order tracking data!', error);
            });
    }, [orderId]);

    const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNote(e.target.value);
        setIsSaved(false); // Reset the animation when note is changed
    };

    const handleSaveNote = () => {
        if (orderTracking) {
            axiosInstance.post('/api/order-tracking/notes', { orderId, note })
                .then(response => {
                    setIsSaved(true); // Trigger animation
                    setTimeout(() => setIsSaved(false), 2000); // Remove animation after 2 seconds
                })
                .catch(error => {
                    console.error('There was an error saving the notes!', error);
                });
        }
    };

    if (!orderTracking) {
        return <div>Loading...</div>;
    }

    const excludeFields = ['id', 'orderTrackingSourceId', 'activeStatus', 'deletedStatus', 'notes'];

    const renderField = (label: string, value: any) => (
        <div key={label} className="p-2 border border-gray-200 rounded">
            <div className="font-semibold">{label}</div>
            <div>{value || 'N/A'}</div>
        </div>
    );

    const orderTrackingEntries = Object.entries(orderTracking)
        .filter(([key]) => !excludeFields.includes(key))
        .map(([key, value]) => {
            if (key === 'trackingNumbers') {
                return (
                    <div key={key} className="p-2 border border-gray-200 rounded">
                        <div className="font-semibold">Tracking Numbers</div>
                        <div>
                            {value && value.length > 0 ? (
                                value.map((tracking: TrackingNumberModel) => (
                                    <span key={tracking.id} className="block">
                                        {tracking.trackingNumber}
                                    </span>
                                ))
                            ) : (
                                <div>No tracking numbers available</div>
                            )}
                        </div>
                    </div>
                );
            }
            if (key === 'orderDate' || key === 'poDate' || key === 'estimatedArrivalDate' || key === 'shippedDate' || key === 'lastUpdated') {
                value = formatDateTime(value);
            }
            return renderField(camelCaseToTitle(key), value);
        });

    return (
        <div className="container mx-auto p-4">
            <Card className="p-4">
                <CardHeader className="relative">
                    <h2 className="text-xl font-bold">Order Details</h2>
                    <div className="absolute top-0 right-0"><BackButton /></div>
                </CardHeader>
                <CardContent>
                    <div className="w-full p-2 border border-gray-200 rounded mb-4">
                        <div className="font-semibold mb-1">Notes</div>
                        <Textarea
                            value={note}
                            onChange={handleNoteChange}
                            className={`w-full ${isSaved ? 'animate-fadeGlow border-1 border-green-500' : 'border'}`}
                            rows={4}
                        />
                        <Button variant="outline" onClick={handleSaveNote} className="mt-2">
                            Save Note
                        </Button>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {orderTrackingEntries}
                    </div>
                </CardContent>
                <CardFooter>
                    <Button variant="outline" className="mt-4">Edit Order</Button>
                </CardFooter>
            </Card>
        </div>
    );
};

export default OrderTrackingDetails;
