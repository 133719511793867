import React, { useState, useEffect } from 'react';
import { DataTable } from '../../../lib/ui/data-table';
import { Source, columns as originalColumns } from './columns';
import { Button } from '../../../lib/ui/button';
import axiosInstance from '../../../../axiosInterceptor';
import { MoreHorizontal } from 'lucide-react';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger
} from '../../../lib/ui/dropdown-menu';
import DeleteDialog from '../../../lib/ui/delete-dialog';

const OrderTrackingSources = () => {
    const [data, setData] = useState<Source[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [sourceToDelete, setSourceToDelete] = useState<Source | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/api/order-tracking-sources/active');
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleDelete = async () => {
        if (!sourceToDelete) return;

        try {
            await axiosInstance.post(`api/order-tracking-sources/delete/${sourceToDelete.id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            setData(prevData => prevData.filter(source => source.id !== sourceToDelete.id));
            setIsDialogOpen(false);
        } catch (error) {
            console.error('Delete failed:', error);
        }
    };

    const columns = originalColumns.map(column => {
        if (column.id === 'actions') {
            return {
                ...column,
                cell: ({ row }) => {
                    const source = row.original;
                    return (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant='ghost' className='h-8 w-8 p-0'>
                                    <span className='sr-only'>Open menu</span>
                                    <MoreHorizontal className='h-4 w-4' />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align='end'>
                                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                                <DropdownMenuItem onClick={() => window.location.href = `order-tracking-sources/edit/${source.id}`}>Edit</DropdownMenuItem>
                                <DropdownMenuItem onClick={() => {
                                    setSourceToDelete(source);
                                    setIsDialogOpen(true);
                                }}>Delete</DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    );
                }
            };
        }
        return column;
    });

    function addSource() {
        window.location.href = `order-tracking-sources/edit/0`;
    }

    return (
        <section className='py-24'>
            <div className='container'>
                <div className="flex items-center justify-between py-4">
                    <h1 className='mb-4 text-3xl font-bold'>Order Tracking Source</h1>
                    <Button className='mb-4' variant="outline" onClick={addSource}>Add</Button>
                </div>
                <div className="rounded-md border">
                    <DataTable columns={columns} data={data} />
                </div>
            </div>

            {sourceToDelete && (
                <DeleteDialog
                    isOpen={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    onConfirm={handleDelete}
                    itemName={sourceToDelete.sourceName}
                />
            )}
        </section>
    );
};

export default OrderTrackingSources;
