import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../lib/ui/card';
import { Button } from '../../lib/ui/button';
import axiosInstance from '../../../axiosInterceptor';
//import BackButton from '../../lib/ui/back-button';

const Admin = () => {

    const manageUsers = () => {
        window.location.href = window.location.origin + "/admin/manage/users";
    };

    const [usersCount, setUsersCount] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                axiosInstance.get('api/accounts/count').then(response => {
                    setUsersCount(response.data);
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    return (
        <section className="py-24">
            <div className="container mx-auto px-4">
                <h1 className="text-3xl font-bold mb-8 text-center">Admin Dashboard</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {true && (
                        <>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Users</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <div className="flex items-center justify-between">
                                        <p>Number of Users: {usersCount} </p>
                                        <Button variant="outline" onClick={manageUsers} className="ml-4">Manage Users</Button>
                                    </div>
                                </CardContent>
                            </Card>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Admin;
