import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from './button';

const BackButton = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Button variant="outline" onClick={handleBack}>
            Back
        </Button>
    );
};

export default BackButton;