"use client"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { Button } from "../../../lib/ui/button"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../lib/ui/form"
import { Input } from "../../../lib/ui/input"
import axiosInstance from "../../../../axiosInterceptor"
import { Switch } from "../../../lib/ui/switch"



const formSchema = z.object({
    username: z.string().min(1, { message: "Required" }),
    email: z.string().min(1, { message: "Required" })
        .email("Enter a valid email address"),
    password: z.string().min(3, { message: "Required" }),
    incomingServer: z.string(),
    outgoingServer: z.string(),
    incomingPort: z.string(),
    outgoingPort: z.string(),
    ssl: z.boolean(),
    isMsal: z.boolean(),
    msalClientId: z.string(),
    msalTennentId: z.string()
})

export function EditEmailSources() {
    const { sourceId } = useParams();
    // 1. Define your form.

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            username: "",
            email: "",
            password: "",
            incomingServer: "",
            outgoingServer: "",
            incomingPort: "",
            outgoingPort: "",
            ssl: true,
            isMsal: false,
            msalClientId: "",
            msalTennentId: ""
        },
    })
    useEffect(() => {
        if (sourceId !== "0") {
            axiosInstance.get(`/api/email-sources/email/${sourceId}`)
                .then(response => {
                    form.setValue('username', response.data.username);
                    form.setValue('email', response.data.email);
                    form.setValue('password', response.data.password);
                    form.setValue('incomingServer', response.data.incomingServer);
                    form.setValue('outgoingServer', response.data.outgoingServer);
                    form.setValue('incomingPort', response.data.incomingPort == null ? null : response.data.incomingPort.toString());
                    form.setValue('outgoingPort', response.data.outgoingPort == null ? null : response.data.outgoingPort.toString());
                    form.setValue('ssl', response.data.ssl);
                    form.setValue('isMsal', response.data.isMsal);
                    form.setValue('msalClientId', response.data.msalClientId);
                    form.setValue('msalTennentId', response.data.msalTennentId);
                })
                .catch(error => {
                    console.error('Failed to fetch data:', error);
                });
        }
    }, [sourceId, form]);
    function onSubmit(values: z.infer<typeof formSchema>) {
        values["id"] = sourceId;
        axiosInstance.post('/api/email-sources/email/save', values, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    window.location.href = window.location.origin + "/email-sources";
                } else {
                    console.log('failed');
                }
            })
            .catch(error => {
                console.error('Request failed:', error);
            });
    }
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <div className="flex">
                    <div className="w-1/2 p-4 ml-10">
                        <FormField
                            control={form.control}
                            name="username"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Username</FormLabel>
                                    <FormControl>
                                        <Input autoComplete="new-password" placeholder="johndoe123" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email Address</FormLabel>
                                    <FormControl>
                                        <Input autoComplete="new-password" placeholder="example@example.com" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="isMsal"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Msal Account</FormLabel>
                                    <FormControl className="flex items-center">
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem>
                                    {form.watch('isMsal') ? (
                                        <FormLabel>Client Secret</FormLabel>
                                    ) : (
                                        <FormLabel>Password</FormLabel>
                                    )}
                                    <FormControl>
                                        <Input
                                            type="password"
                                            autoComplete="new-password"
                                            placeholder={form.watch('isMsal') ? "Client Secret" : "Password"}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="incomingServer"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Incoming Server</FormLabel>
                                    <FormControl>
                                        <Input placeholder="imap.example.com" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="incomingPort"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Incoming Port</FormLabel>
                                    <FormControl>
                                        <Input placeholder="143" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="outgoingServer"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Outgoing Server</FormLabel>
                                    <FormControl>
                                        <Input placeholder="smtp.example.com" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="outgoingPort"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Outoing Port</FormLabel>
                                    <FormControl>
                                        <Input placeholder="143" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="msalTennentId"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Tennent Id</FormLabel>
                                    <FormControl>
                                        <Input placeholder="id" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="msalClientId"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Client Id</FormLabel>
                                    <FormControl>
                                        <Input placeholder="id" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="ssl"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Use SSL</FormLabel>
                                    <FormControl className="flex items-center">
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button variant="outline" type="submit">Save</Button>
                    </div>
                </div>
            </form>
        </Form>
    )
}


export default EditEmailSources;
