import axios from 'axios';

const axiosInstance = axios.create({
    withCredentials: true, // Send cookies with requests
});

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry && originalRequest?.url !== '/auth/refresh-token') {
            originalRequest._retry = true;

            try {
                const refreshResponse = await axios.post('/auth/refresh-token');
                if (refreshResponse.status === 204 || refreshResponse.status === 200) {
                    return axiosInstance(originalRequest);
                }
                else {
                    throw new Error("User has no valid refresh token");
                }
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                // Handle token refresh failure (e.g., logout user, show error message)
                throw refreshError;
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
