import React, { useEffect } from "react";
import {
    ColumnDef,
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../lib/ui/table";
import { Button } from "../../lib/ui/button";
import { Input } from "../../lib/ui/input";
import { DataTablePagination } from "../../lib/ui/data-table-pagination";
import { DataTableViewOptions } from "../../lib/ui/data-table-view-options";
import axiosInstance, { getCookie } from "../../../axiosInterceptor";
import { Switch } from "../../lib/ui/switch";

export interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    totalRecords: number;
    pagesCount: number;
    rowsPerPage: number;
    currentPage: number;
    totalRows: number;
    poNumParam: string;
    itemNumParam: string;
    shipToNameParam: string;
    suppNameParam: string;
    brandNameParam: string;
    trackingNumberParam: string;
    andOrParam: boolean;
    searchClicked: boolean;
    setPoNumParam: React.Dispatch<React.SetStateAction<string>>;
    setItemNumParam: React.Dispatch<React.SetStateAction<string>>;
    setShipToNameParam: React.Dispatch<React.SetStateAction<string>>;
    setSuppNameParam: React.Dispatch<React.SetStateAction<string>>;
    setBrandNameParam: React.Dispatch<React.SetStateAction<string>>;
    setTrackingNumberParam: React.Dispatch<React.SetStateAction<string>>;
    setAndOrParam: React.Dispatch<React.SetStateAction<boolean>>;
    setSearchClicked: React.Dispatch<React.SetStateAction<boolean>>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
    setSorting: React.Dispatch<React.SetStateAction<any>>;
}

var userColumnVisibility = {
    accountNumber: true,
    brandName: true,
    customerName: true,
    customerNumber: true,
    customerPoNumber: true,
    documentNumber: true,
    estimatedArrivalDate: true,
    itemNumber: true,
    itemStatus: true,
    lineStatus: true,
    notes: true,
    orderDate: true,
    poDate: false,
    qty: true,
    qtyFulfilled: true,
    shipToName: true,
    shippedDate: true,
    supplierName: true,
    supplierOrderStatus: true,
    trackingNumbers: true,
    wildcard1: true,
    wildcard2: true,
};

export function DataTable<TData, TValue>({
    columns,
    data,
    totalRecords,
    pagesCount,
    rowsPerPage,
    currentPage,
    poNumParam,
    itemNumParam,
    shipToNameParam,
    suppNameParam,
    brandNameParam,
    trackingNumberParam,
    andOrParam,
    searchClicked,
    totalRows,
    setPoNumParam,
    setItemNumParam,
    setShipToNameParam,
    setSuppNameParam,
    setBrandNameParam,
    setTrackingNumberParam,
    setAndOrParam,
    setSearchClicked,
    setPage,
    setRowsPerPage,
    setSorting,
}: DataTableProps<TData, TValue>) {
    const [sorting, setSortingState] = React.useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(userColumnVisibility);
    const [rowSelection, setRowSelection] = React.useState({});

    useEffect(() => {
        const fetchUserColumnVisibility = async () => {
            var cookieUsername = getCookie('X-Username');
            axiosInstance.get(`/api/accounts/order-tracking-settings/username?userName=${cookieUsername}`)
                .then(response => {
                    if (response.status === 200 || response.status === 204) {
                        const enabledColumns = response.data.enabledColumns;
                        const allNullOrFalse = Object.values(enabledColumns).every(value => value === null || value === false || value === 0);
                        if (allNullOrFalse) {
                            setColumnVisibility(Object.keys(enabledColumns).reduce((acc, key) => {
                                acc[key] = true;
                                return acc;
                            }, {}));
                        } else {
                            console.log('failed');
                            setColumnVisibility(enabledColumns);
                        }
                    } else {
                        console.log('failed');
                    }
                })
                .catch(error => {
                    console.error('Request failed:', error);
                });
        };

        fetchUserColumnVisibility();
    }, []);

    useEffect(() => {
        const sortingModel = sorting.reduce((acc, sort) => {
            acc[sort.id] = sort.desc ? false : true;
            return acc;
        }, {});
        setSorting(sortingModel);
    }, [sorting, setSorting]);

    const table = useReactTable({
        data,
        columns,
        manualPagination: true,
        manualFiltering: true,
        manualSorting: true, // Use manual sorting
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSortingState,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    });

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = () => {
        setSearchClicked(!searchClicked);
    };

    const resetParams = () => {
        setPoNumParam('');
        setItemNumParam('');
        setShipToNameParam('');
        setSuppNameParam('');
        setBrandNameParam('');
        setTrackingNumberParam('');
        setAndOrParam(false);
        setSearchClicked(!searchClicked);
    }

    const newPaddingParam = "px-1";
    return (
        <div className="">
            <div className="flex flex-wrap items-center justify-between py-4 gap-4">
                <div className="w-full sm:w-auto flex items-center">
                    <Switch 
                        className="mr-3"
                        checked={andOrParam}
                        onCheckedChange={(value) => setAndOrParam(!!value)}
                        aria-label="And/Or"
                    />
                    {andOrParam ? 'AND' : 'OR   '}
                </div>

                <div className="w-full sm:w-1/5">
                    <Input
                        placeholder="PO #"
                        value={poNumParam}
                        onChange={(event) => setPoNumParam(event.target.value.trim())}
                        onKeyDown={handleKeyDown}
                        className="max-w-full"
                    />
                </div>

                <div className="w-full sm:w-1/5">
                    <Input
                        placeholder="Item #"
                        value={itemNumParam}
                        onChange={(event) => setItemNumParam(event.target.value.trim())}
                        onKeyDown={handleKeyDown}
                        className="max-w-full"
                    />
                </div>

                <div className="w-full sm:w-1/5">
                    <Input
                        placeholder="Ship To/Customer Name"
                        value={shipToNameParam}
                        onChange={(event) => setShipToNameParam(event.target.value.trim())}
                        onKeyDown={handleKeyDown}
                        className="max-w-full"
                    />
                </div>

                <div className="w-full sm:w-1/5">
                    <Input
                        placeholder="Supplier Name"
                        value={suppNameParam}
                        onChange={(event) => setSuppNameParam(event.target.value.trim())}
                        onKeyDown={handleKeyDown}
                        className="max-w-full"
                    />
                </div>

                <div className="w-full sm:w-1/5">
                    <Input
                        placeholder="Brand Name"
                        value={brandNameParam}
                        onChange={(event) => setBrandNameParam(event.target.value.trim())}
                        onKeyDown={handleKeyDown}
                        className="max-w-full"
                    />
                </div>

                <div className="w-full sm:w-1/5">
                    <Input
                        placeholder="Tracking Number"
                        value={trackingNumberParam}
                        onChange={(event) => setTrackingNumberParam(event.target.value.trim())}
                        onKeyDown={handleKeyDown}
                        className="max-w-full"
                    />
                </div>

                <div className="w-full sm:w-auto flex gap-2">
                    <Button variant="outline" onClick={resetParams}>Reset</Button>
                    <Button variant="outline" onClick={handleSearch}>Search</Button>
                    <DataTableViewOptions table={table} />
                </div>
            </div>

            <div className="rounded-md border overflow-auto">
                <div className="relative overflow-auto" style={{ maxHeight: '70vh' }}> {/* Set your desired height */}
                    <Table>
                        <TableHeader className="sticky top-0 z-1 bg-accent">
                            <TableRow className="">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <React.Fragment key={headerGroup.id}>
                                        {headerGroup.headers.map((header, index) => (
                                            <TableHead key={header.id} className={index === 0 ? '' : newPaddingParam}>
                                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                            </TableHead>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {table.getRowModel().rows?.length ? (
                                table.getRowModel().rows.map((row) => (
                                    <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                                        {row.getVisibleCells().map((cell, index) => (
                                            <TableCell key={cell.id} className={(index === 0 ? '' : newPaddingParam) + ' py-2'}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={columns.length} className="h-24 text-center">
                                        No results.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                </div>
            <DataTablePagination
                table={table}
                currentPage={currentPage}
                pagesCount={pagesCount}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                totalRecords={totalRecords}
            />
        </div>
    );
}
