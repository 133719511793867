"use client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { Button } from "../../../lib/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../lib/ui/form";
import { Input } from "../../../lib/ui/input";
import { Textarea } from "../../../lib/ui/textarea";
import axiosInstance from "../../../../axiosInterceptor";
import { Switch } from "../../../lib/ui/switch";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../lib/ui/select";

const formSchema = z.object({
    id: z.number().optional(),
    sourceName: z.string().min(1, { message: "Required" }),
    emailSourceId: z.string().min(1, { message: "Required" }),
    fileType: z.string().min(1, { message: "Required" }),
    fromEmail: z.string().min(1, { message: "Required" }),
    daysUntilNoUpdateNotification: z.string(),
    expectedAttachmentCount: z.string(),
    subjectValue: z.string().min(1, { message: "Required" }),
    containsNotExact: z.boolean(),
    attachmentName: z.string().min(1, { message: "Required" }),
    itemNumberColumnName: z.string().min(1, { message: "Required" }),
    qtyColumnName: z.string().min(1, { message: "Required" }),
    statusSupersedesQuantity: z.boolean(),
    statusAvailableList: z.string(),
    statusOutOfStockList: z.string(),
    minItemsToBeActive: z.string(),
    fileHasHeader: z.boolean(),
    sftpServerAddress: z.string(),
    sftpPort: z.string(),
    sftpUsername: z.string(),
    sftpPassword: z.string(),
    activeStatus: z.boolean(),
    fileData: z.object({
        userActivatedItemNumbers: z.string().min(1, { message: "Required" }),
        inventoryFile: z.instanceof(File).optional(),
        activeStatus: z.boolean(),
        headerRowIndex: z.string(),
        fileHasHeader: z.boolean(),
        itemNumberColumnName: z.string(),
        itemStatusColumnName: z.string(),
        itemStatusDateColumnName: z.string(),
    }).optional(),
});

export function EditInventorySource() {
    const { sourceId } = useParams();
    const [emailList, setEmailList] = useState([]);
    const [existingFile, setExistingFile] = useState<{ fileName: string, fileData: string } | null>(null);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            id: 0,
            emailSourceId: "",
            sourceName: "",
            fileType: "",
            fromEmail: "",
            daysUntilNoUpdateNotification: "",
            expectedAttachmentCount: "",
            subjectValue: "",
            containsNotExact: false,
            attachmentName: "",
            itemNumberColumnName: "",
            qtyColumnName: "",
            statusSupersedesQuantity: false,
            statusAvailableList: "",
            statusOutOfStockList: "",
            minItemsToBeActive: "",
            fileHasHeader: false,
            sftpServerAddress: "",
            sftpPort: "",
            sftpUsername: "",
            sftpPassword: "",
            activeStatus: true,
            fileData: {
                userActivatedItemNumbers: "",
                activeStatus: true,
                headerRowIndex: "",
                fileHasHeader: true,
                itemNumberColumnName: "",
                itemStatusColumnName: "",
                itemStatusDateColumnName: "",
            },
        },
    });

    useEffect(() => {
        if (sourceId !== "0") {
            axiosInstance.get(`/api/inventory-sources/inventory/${sourceId}`)
                .then(response => {
                    const data = response.data;
                    form.setValue('id', data.id);
                    form.setValue('emailSourceId', data.emailSourceId);
                    form.setValue('sourceName', data.sourceName);
                    form.setValue('fileType', data.fileType);
                    form.setValue('fromEmail', data.fromEmail);
                    form.setValue('daysUntilNoUpdateNotification', data.daysUntilNoUpdateNotification == null ? null : data.daysUntilNoUpdateNotification.toString());
                    form.setValue('expectedAttachmentCount', data.expectedAttachmentCount == null ? null : data.expectedAttachmentCount.toString());
                    form.setValue('subjectValue', data.subjectValue);
                    form.setValue('containsNotExact', data.containsNotExact);
                    form.setValue('attachmentName', data.attachmentName);
                    form.setValue('itemNumberColumnName', data.itemNumberColumnName == null ? null : data.itemNumberColumnName.toString());
                    form.setValue('qtyColumnName', data.qtyColumnName == null ? null : data.qtyColumnName.toString());
                    form.setValue('statusSupersedesQuantity', data.statusSupersedesQuantity);
                    form.setValue('statusAvailableList', data.statusAvailableList);
                    form.setValue('statusOutOfStockList', data.statusOutOfStockList);
                    form.setValue('minItemsToBeActive', data.minItemsToBeActive == null ? null : data.minItemsToBeActive.toString());
                    form.setValue('fileHasHeader', data.fileHasHeader);
                    form.setValue('sftpServerAddress', data.sftpServerAddress);
                    form.setValue('sftpPort', data.sftpPort != null ? data.sftpPort.toString() : '');
                    form.setValue('sftpUsername', data.sftpUsername);
                    form.setValue('sftpPassword', data.sftpPassword);
                    form.setValue('activeStatus', data.activeStatus);
                    form.setValue('fileData.userActivatedItemNumbers', data.fileData.userActivatedItemNumbers);
                    form.setValue('fileData.headerRowIndex', data.fileData.headerRowIndex == null ? null : data.fileData.headerRowIndex.toString());
                    form.setValue('fileData.fileHasHeader', data.fileData.fileHasHeader); 
                    form.setValue('fileData.itemNumberColumnName', data.fileData.itemNumberColumnName == null ? null : data.fileData.itemNumberColumnName.toString());
                    form.setValue('fileData.itemStatusColumnName', data.fileData.itemStatusColumnName == null ? null : data.fileData.itemStatusColumnName.toString());
                    form.setValue('fileData.itemStatusDateColumnName', data.fileData.itemStatusDateColumnName == null ? null : data.fileData.itemStatusDateColumnName.toString());

                    if (data.fileData.inventoryFileName && data.fileData.inventoryFileData) {
                        setExistingFile({
                            fileName: data.fileData.inventoryFileName,
                            fileData: data.fileData.inventoryFileData
                        });
                    }
                })
                .catch(error => {
                    console.error('Failed to fetch data:', error);
                });
        }
        axiosInstance.get(`/api/email-sources/email-list`)
            .then(response => {
                setEmailList(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch email sources:', error);
            });
    }, [sourceId, form]);

    async function onSubmit(values: z.infer<typeof formSchema>) {
        values.daysUntilNoUpdateNotification = parseNullableIntToString(values.daysUntilNoUpdateNotification);
        values.expectedAttachmentCount = parseNullableIntToString(values.expectedAttachmentCount);
        values.minItemsToBeActive = parseNullableIntToString(values.minItemsToBeActive);
        values.sftpPort = parseNullableIntToString(values.sftpPort);;
        values.fileData.headerRowIndex = parseNullableIntToString(values.fileData.headerRowIndex);;
        values.fileData.itemNumberColumnName = parseNullableIntToString(values.fileData.itemNumberColumnName);;
        values.fileData.itemStatusColumnName = parseNullableIntToString(values.fileData.itemStatusColumnName);;
        values.fileData.itemStatusDateColumnName = parseNullableIntToString(values.fileData.itemStatusDateColumnName);;
        const selectedEmail = emailList.find(email => email.item1 === values.emailSourceId);
        if (selectedEmail) {
            values.emailSourceId = selectedEmail.item2;
        }
        values.sftpPort = null;
        if (values.fileData?.inventoryFile) {
            const reader = new FileReader();
            reader.readAsDataURL(values.fileData.inventoryFile);
            reader.onload = () => {
                const base64Data = reader.result as string;
                const fileData = {
                    ...values.fileData,
                    inventoryFile: base64Data.split(",")[1], // Remove the data URL prefix
                    inventoryFileName: values.fileData.inventoryFile.name,
                    activeStatus: values.activeStatus,
                    headerRowIndex: values.fileData.headerRowIndex,
                    fileHasHeader: values.fileData.fileHasHeader,
                    itemNumberColumnName: values.fileData.itemNumberColumnName,
                    itemStatusColumnName: values.fileData.itemStatusColumnName,
                    itemStatusDateColumnName: values.fileData.itemStatusDateColumnName,
                };

                const payload = {
                    ...values,
                    fileData
                };

                axiosInstance.post('/api/inventory-sources/inventory/save', payload, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then(response => {
                        if (response.status === 200 || response.status === 204) {
                            window.location.href = window.location.origin + "/inventory-sources";
                        } else {
                            console.log('failed');
                        }
                    })
                    .catch(error => {
                        console.error('Request failed:', error);
                    });
            };
        } else {
            axiosInstance.post('/api/inventory-sources/inventory/save', values, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.status === 200 || response.status === 204) {
                        window.location.href = window.location.origin + "/inventory-sources";
                    } else {
                        console.log('failed');
                    }
                })
                .catch(error => {
                    console.error('Request failed:', error);
                });
        }
    }

    function parseNullableIntToString(value: string | undefined): string | null {
        if (value === undefined || value.trim() === "") {
            return null;
        }
        return value;
    }

    const handleDownload = () => {
        if (existingFile) {
            const byteCharacters = atob(existingFile.fileData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = existingFile.fileName;
            link.click();
        }
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <div className="flex">
                    <div className="w-1/2 p-4 ml-10">
                        <FormLabel className="text-2xl mt-4 mb-2">Receiving File Information</FormLabel>
                        <FormField
                            control={form.control}
                            name="sourceName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Source Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Source Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="emailSourceId"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email Sources</FormLabel>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select an email" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {emailList.map(email => (
                                                <SelectItem key={email.item1} value={email.item2.toString()}>
                                                    {email.item1}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="fileType"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>File Type</FormLabel>
                                    <FormControl>
                                        <Input placeholder="File Type" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="fromEmail"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>From Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="From Email" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="daysUntilNoUpdateNotification"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Days until no update notification is sent</FormLabel>
                                    <FormControl>
                                        <Input placeholder="7" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="expectedAttachmentCount"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Expected Attachment Count</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Expected Attachment Count" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="subjectValue"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Subject Value</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Subject Value" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="containsNotExact"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Subject contains the above text not an exact match</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="attachmentName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Attachment Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Attachment Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="fileHasHeader"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">File Has Header</FormLabel>
                                    <FormControl className="flex items-center">
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="itemNumberColumnName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>{form.watch('fileHasHeader') ? 'Item Number Column Name' : 'Item Number Index'}</FormLabel>
                                    <FormControl>
                                        <Input placeholder={form.watch('fileHasHeader') ? 'Item Number Column Name' : 'Item Number Index'} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="qtyColumnName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>{form.watch('fileHasHeader') ? 'Quantity Column Name' : 'Quantity Column Index'}</FormLabel>
                                    <FormControl>
                                        <Input placeholder={form.watch('fileHasHeader') ? 'Quantity Column Name' : 'Quantity Column Index'} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="statusSupersedesQuantity"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Status Supersedes Quantity</FormLabel>
                                    <FormControl className="flex items-center">
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {form.watch('statusSupersedesQuantity') && (
                            <>
                                <FormField
                                    control={form.control}
                                    name="statusAvailableList"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Comma Separated list of status values thay equate to "In Stock/Active"</FormLabel>
                                            <FormControl>
                                                <Input placeholder="Active, New, ..." {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="statusOutOfStockList"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Comma Separated list of status values thay equate to "Out of Stock/Inactive"</FormLabel>
                                            <FormControl>
                                                <Input placeholder="Backordered, Inactive, ..." {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </>
                        )}
                        <FormField
                            control={form.control}
                            name="minItemsToBeActive"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Min Items To Be Active</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Min Items To Be Active" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormLabel className="text-2xl mt-4 mb-2">File Submission Information</FormLabel>
                        <FormField
                            control={form.control}
                            name="sftpServerAddress"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Sftp Server Address</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Sftp Server Address" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="sftpPort"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Sftp Port</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Sftp Port" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="sftpUsername"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Sftp Username</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Sftp Username" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="sftpPassword"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Sftp Password</FormLabel>
                                    <FormControl>
                                        <Input type="password" autoComplete="new-password" placeholder="Sftp Password" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="activeStatus"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Active Status</FormLabel>
                                    <FormControl className="flex items-center">
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button variant="outline" type="submit">Save</Button>
                    </div>
                    <div className="w-1/2 p-4">
                        <FormLabel className="text-2xl mt-4 mb-2">File Generation Information</FormLabel>
                        <FormField
                            control={form.control}
                            name="fileData.userActivatedItemNumbers"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>User Activated Item Numbers</FormLabel>
                                    <FormControl>
                                        <Textarea placeholder="User Activated Item Numbers" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="fileData.inventoryFile"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Upload Inventory File</FormLabel>
                                    <FormControl>
                                        <Input type="file" accept=".xls,.xlsx" onChange={(e) => {
                                            if (e.target.files && e.target.files[0]) {
                                                field.onChange(e.target.files[0]);
                                            }
                                        }} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="fileData.fileHasHeader"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">File Has Header</FormLabel>
                                    <FormControl className="flex items-center">
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {existingFile && (
                            <div className="mt-4">
                                <p>Existing File: {existingFile.fileName}</p>
                                <Button variant="outline" type="button" onClick={handleDownload}>Download File</Button>
                            </div>
                        )}
                        {form.watch('fileData.fileHasHeader') && (<FormField
                            control={form.control}
                            name="fileData.headerRowIndex"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Header row Index</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Index of header row" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />)}
                        <FormField
                            control={form.control}
                            name="fileData.itemNumberColumnName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>{form.watch('fileData.fileHasHeader') ? 'Item Number Column Name' : 'Item Number Index'}</FormLabel>
                                    <FormControl>
                                        <Input placeholder={form.watch('fileData.fileHasHeader') ? 'Item Number Column Name' : 'Item Number Index'} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="fileData.itemStatusColumnName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>{form.watch('fileData.fileHasHeader') ? 'Status Column Name' : 'Status Column Index'}</FormLabel>
                                    <FormControl>
                                        <Input placeholder={form.watch('fileData.fileHasHeader') ? 'Status Column Name' : 'Status Column Index'} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="fileData.itemStatusDateColumnName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>{form.watch('fileData.fileHasHeader') ? 'Status Date Column Name' : 'Status Date Column Index'}</FormLabel>
                                    <FormControl>
                                        <Input placeholder={form.watch('fileData.fileHasHeader') ? 'Status Date Column Name' : 'Status Date Column Index'} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
            </form>
        </Form>
    );
}

export default EditInventorySource;
