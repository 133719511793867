import React from 'react';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../../lib/ui/form';
import { Input } from '../../../lib/ui/input';
import { Button } from '../../../lib/ui/button';
import { z } from 'zod';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import axiosInstance from '../../../../axiosInterceptor';
import { Switch } from '../../../lib/ui/switch';
import { useEffect } from 'react';


const formSchema = z.object({
    username: z.string().min(1, { message: "Required" }),
    email: z.string().min(1, { message: "Required" })
        .email("Enter a valid email address"),
    firstName: z.string(),
    lastName: z.string(),
    isAdmin: z.boolean(),
    inventoryUser: z.boolean(),
    orderUser: z.boolean(),
    submissionUser: z.boolean(),
    sendNotificationsToEmail: z.boolean(),
    activeStatus: z.boolean(),
    password: z.string(),
})

const EditUser = () => {
    const { userId } = useParams();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            username: "",
            firstName: "",
            lastName: "",
            isAdmin: false,
            inventoryUser: false,
            orderUser: true,
            submissionUser: false,
            sendNotificationsToEmail: false,
            activeStatus: false,
            password: "",
        },
    })
    useEffect(() => {
        if (userId !== "0") {
            axiosInstance.get(`/api/accounts/user/${userId}`)
                .then(response => {
                    form.setValue('username', response.data.username);
                    form.setValue('email', response.data.email);
                    form.setValue('firstName', response.data.firstName);
                    form.setValue('lastName', response.data.lastName);
                    form.setValue('isAdmin', response.data.isAdmin);
                    form.setValue('inventoryUser', response.data.inventoryUser);
                    form.setValue('orderUser', response.data.orderUser);
                    form.setValue('submissionUser', response.data.submissionUser);
                    form.setValue('sendNotificationsToEmail', response.data.sendNotificationsToEmail);
                    form.setValue('activeStatus', response.data.activeStatus);
                })
                .catch(error => {
                    console.error('Failed to fetch data:', error);
                });
        }
    }, [userId, form]);
    function onSubmit(values: z.infer<typeof formSchema>) {
        values["userId"] = userId;
        axiosInstance.post('/api/accounts/update/user', values, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    window.location.href = window.location.origin + "/admin/manage/users";
                } else {
                    console.log('failed');
                }
            })
            .catch(error => {
                console.error('Request failed:', error);
            });
    }
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <div className="flex">
                    <div className="w-1/2 p-4 ml-10">
                        <FormField
                            control={form.control}
                            name="username"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Username</FormLabel>
                                    <FormControl>
                                        <Input autoComplete="new-password" placeholder="johndoe123" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email Address</FormLabel>
                                    <FormControl>
                                        <Input autoComplete="new-password" placeholder="example@example.com" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="firstName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>First Name</FormLabel>
                                    <FormControl>
                                        <Input autoComplete="new-password" placeholder="John" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="lastName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Last Name</FormLabel>
                                    <FormControl>
                                        <Input autoComplete="new-password" placeholder="Doe" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="isAdmin"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Admin</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="inventoryUser"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Inventory Processing User</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="orderUser"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Order Tracking User</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="submissionUser"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Order Submission User</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="sendNotificationsToEmail"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Send Notifications To Email</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="activeStatus"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Active Status</FormLabel>
                                    <FormControl className="flex items-center">
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>User Password</FormLabel><FormLabel className="text-gray-500 pl-2">- Leave password blank if you want the user to set their own password or leave password unchnaged for edits</FormLabel>
                                    <FormControl>
                                        <Input type="password"
                                            autoComplete="new-password"
                                            placeholder="Password" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button variant="outline" type="submit">Save</Button>
                    </div>
                </div>
            </form>
        </Form>
    );
};

export default EditUser;
