"use client"

import { ColumnDef } from "@tanstack/react-table"
import React, { useEffect, useRef, useState } from "react"
import { DataTableColumnHeader } from "../../lib/ui/data-table-column-header"
import { Switch } from "../../lib/ui/switch"

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type OrderTrackingOrder = {
    id: number
    orderTrackingSourceId: number
    accountNumber: string
    brandName: string
    customerName: string
    customerNumber: string
    customerPoNumber: string
    documentNumber: string
    estimatedArrivalDate: string
    itemNumber: string
    itemStatus: string
    lineStatus: string
    notes: string
    orderDate: string
    poDate: string
    qty: number
    qtyFulfilled: number
    shipToName: string
    shippedDate: string
    supplierName: string
    supplierOrderStatus: string
    trackingNumbers: string
    wildcard1: string
    wildcard2: string
}

const NotesCell = ({ value }) => {
    const [truncatedValue, setTruncatedValue] = useState(value);
    const cellRef = useRef(null);

    useEffect(() => {
        if (cellRef.current) {
            const cellWidth = cellRef.current.clientWidth;
            const charWidth = 4; // average character width in pixels, adjust based on font
            const maxChars = Math.floor(cellWidth / charWidth);
            if (value.length > maxChars) {
                setTruncatedValue(value.substring(0, maxChars) + "...");
            } else {
                setTruncatedValue(value);
            }
        }
    }, [value]);

    return <div ref={cellRef} >{truncatedValue}</div>;
};

const cellClass = 'font-medium';
export const columns: ColumnDef<OrderTrackingOrder>[] = [
    {
        id: "select",
        header: ({ table }) => (
            <Switch
                checked={
                    table.getIsAllPageRowsSelected() ||
                    (table.getIsSomePageRowsSelected())
                }
                onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                aria-label="Select all"
            />
        ),
        cell: ({ row }) => (
            <Switch
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
            />
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: "brandName",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Brand" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("brandName");
            return <div className={cellClass} >{rowData}</div>
        },
    },
    {
        accessorKey: "customerName",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Customer" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("customerName");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        accessorKey: "customerNumber",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Customer. #" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("customerNumber");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        accessorKey: "customerPoNumber",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Customer Po #" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("customerPoNumber");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        accessorKey: "documentNumber",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Doc #" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("documentNumber");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        accessorKey: "estimatedArrivalDate",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Est. Arrival Date" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("estimatedArrivalDate");
            var formatted = null;
            if (rowData !== null && rowData !== "") {
                const date = new Date(rowData);
                formatted = Intl.DateTimeFormat("en-US").format(date);
            }
            return <div className={cellClass}>{formatted}</div>
        },
    },
    {
        accessorKey: "itemNumber",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Item #" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("itemNumber");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        accessorKey: "itemStatus",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Item Status" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("itemStatus");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        accessorKey: "lineStatus",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Line Status" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("lineStatus");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        accessorKey: "notes",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Notes" />
        ),
        cell: ({ row }) => {
            const rowData: string = row.getValue("notes");
            return <NotesCell value={rowData} />;
        },
    },
    {
        accessorKey: "orderDate",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Order Date" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("orderDate");
            var formatted = null;
            if (rowData !== null && rowData !== "") {
                const date = new Date(rowData);
                formatted = Intl.DateTimeFormat("en-US").format(date);
            }
            return <div className={cellClass}>{formatted}</div>
        },
    },
    {
        accessorKey: "poDate",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="PO Date" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("poDate");
            var formatted = null;
            if (rowData !== null && rowData !== "") {
                const date = new Date(rowData);
                formatted = Intl.DateTimeFormat("en-US").format(date);
            }
            return <div className={cellClass}>{formatted}</div>
        },
    },
    {
        accessorKey: "qty",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Qty" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("qty");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        accessorKey: "qtyFulfilled",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Qty Fufilled" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("qtyFulfilled");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        accessorKey: "shipToName",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Ship to Name" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("shipToName");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        accessorKey: "shippedDate",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Shipped Date" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("shippedDate");
            var formatted = null;
            if (rowData !== null && rowData !== "") {
                const date = new Date(rowData);
                formatted = Intl.DateTimeFormat("en-US").format(date);
            }
            return <div className={cellClass}>{formatted}</div>
        },
    },
    {
        accessorKey: "supplierName",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Supplier Name" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("supplierName");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        accessorKey: "supplierOrderStatus",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Supplier Status" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("supplierOrderStatus");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        accessorKey: "trackingNumbers",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Tracking Numbers" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("trackingNumbers");
            return <div className={cellClass}>{rowData}</div>
        },
        enableSorting: false,
    },
    {
        accessorKey: "wildcard1",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Wildcard 1" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("wildcard1");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        accessorKey: "wildcard2",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Wildcard 2" />
        ),
        cell: ({ row }) => {
            var rowData: string = row.getValue("wildcard2");
            return <div className={cellClass}>{rowData}</div>
        },
    },
    {
        id: "actions",
    },
]