import React, { useCallback, useEffect, useState } from 'react';
import { OrderTrackingOrder, columns as originalColumns } from "./columns";
import { DataTable } from "./data-table";
import axiosInstance from '../../../axiosInterceptor';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '../../lib/ui/dropdown-menu';
import { Button } from '../../lib/ui/button';
import { MoreHorizontal } from 'lucide-react';
import DeleteDialog from '../../lib/ui/delete-dialog';

const OrderTrackingOrders = () => {
    const [data, setData] = useState<OrderTrackingOrder[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [orderTrackingToDelete, setOrderTrackingToDelete] = useState<OrderTrackingOrder | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [poNumParam, setPoNumParam] = useState("");
    const [itemNumParam, setItemNumParam] = useState("");
    const [shipToNameParam, setShipToNameParam] = useState("");
    const [suppNameParam, setSuppNameParam] = useState("");
    const [brandNameParam, setBrandNameParam] = useState("");
    const [trackingNumberParam, setTrackingNumberParam] = useState("");
    const [andOrParam, setAndOrParam] = useState(false);
    const [searchClicked, setSearchClicked] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [sorting, setSorting] = useState({});

    

    const fetchData = useCallback(async () => {
        try {
            const response = await axiosInstance.post('/api/order-tracking/list', {
                PageSize: perPage,
                PageIndex: currentPage,
                PoNumberParam: poNumParam,
                ItemNumberParam: itemNumParam,
                ShipToNameParam: shipToNameParam,
                SupplierNameParam: suppNameParam,
                BrandNameParam: brandNameParam,
                TrackingNumberParam: trackingNumberParam,
                AndOrParam: andOrParam,
                SortParams: sorting
            });
            const transformedOrders = response.data.orders.map(order => ({
                ...order,
                trackingNumbers: order.trackingNumbers
                    .filter(tracking => tracking !== null)
                    .map(tracking => tracking.trackingNumber)
                    .join(', ')
            }));
            setData(transformedOrders);
            setTotalCount(response.data.totalRecordCount);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [currentPage, perPage, poNumParam, itemNumParam, shipToNameParam, suppNameParam, brandNameParam, trackingNumberParam, andOrParam, sorting]);

    useEffect(() => {
        fetchData();
    }, [currentPage, perPage, searchClicked, sorting, fetchData]);

    const handleDelete = async () => {
        if (!orderTrackingToDelete) return;

        try {
            await axiosInstance.post(`api/order-tracking/delete/${orderTrackingToDelete.id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            setData(prevData => prevData.filter(order => order.id !== orderTrackingToDelete.id));
            setIsDialogOpen(false);
        } catch (error) {
            console.error('Delete failed:', error);
        }
    };

    const columns = originalColumns.map(column => {
        if (column.id === 'actions') {
            return {
                ...column,
                cell: ({ row }) => {
                    const orderTrackingInfo = row.original;

                    return (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="h-8 w-8 p-0">
                                    <span className="sr-only">Open menu</span>
                                    <MoreHorizontal className="h-4 w-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                                <DropdownMenuItem onClick={() => navigator.clipboard.writeText(orderTrackingInfo.customerPoNumber)}>Copy Po #</DropdownMenuItem>
                                <DropdownMenuSeparator />
                                <DropdownMenuItem onClick={() => navigator.clipboard.writeText(orderTrackingInfo.trackingNumbers)}>Copy Tracking Numbers</DropdownMenuItem>
                                <DropdownMenuItem onClick={() => window.location.href = `orders/order/${orderTrackingInfo.id}`}>View Order</DropdownMenuItem>
                                <DropdownMenuItem onClick={() => {
                                    setOrderTrackingToDelete(orderTrackingInfo);
                                    setIsDialogOpen(true);
                                }}>Delete Order</DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )
                },
            };
        }
        return column;
    });

    return (
        <div className="container mx-auto py-10">
            <DataTable
                columns={columns}
                data={data}
                totalRecords={totalCount}
                pagesCount={Math.ceil(totalCount / perPage)}
                rowsPerPage={perPage}
                currentPage={currentPage}
                poNumParam={poNumParam}
                setPoNumParam={setPoNumParam}
                itemNumParam={itemNumParam}
                setItemNumParam={setItemNumParam}
                shipToNameParam={shipToNameParam}
                setShipToNameParam={setShipToNameParam}
                suppNameParam={suppNameParam}
                setSuppNameParam={setSuppNameParam}
                brandNameParam={brandNameParam}
                setBrandNameParam={setBrandNameParam}
                trackingNumberParam={trackingNumberParam}
                setTrackingNumberParam={setTrackingNumberParam}
                andOrParam={andOrParam}
                setAndOrParam={setAndOrParam}
                searchClicked={searchClicked}
                setSearchClicked={setSearchClicked}
                setPage={setCurrentPage}
                setRowsPerPage={setPerPage}
                totalRows={totalCount}
                setSorting={setSorting}
            />

            {orderTrackingToDelete && (
                <DeleteDialog
                    isOpen={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    onConfirm={handleDelete}
                    itemName={orderTrackingToDelete.customerPoNumber}
                />
            )}
        </div>
    );
};

export default OrderTrackingOrders;
