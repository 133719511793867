import React, { useEffect, useState } from 'react';
import { Button } from "../../lib/ui/button";
import axiosInstance, { getCookie } from "../../../axiosInterceptor"
import { Card, CardContent, CardHeader, CardTitle } from '../../lib/ui/card';
import { format } from 'date-fns';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../lib/ui/dialog';
import { Label } from '../../lib/ui/label';
import { Input } from '../../lib/ui/input';
import { Switch } from '../../lib/ui/switch';

export type UserInfo = {
    userId: number,
    username: string,
    email: string,
    firstName: string,
    lastName: string,
    createdOn: string,
    lastLogin: string
}

export type UserOrderTrackingViewSettingsModel = {
    id: number,
    userId: number,
    enabledColumns: UserOrderTrackingViewEnabledColumnsModel
}

export type UserOrderTrackingViewEnabledColumnsModel = {
    id: number,
    accountNumber: boolean,
    brandName: boolean,
    customerName: boolean,
    customerNumber: boolean,
    customerPoNumber: boolean,
    documentNumber: boolean,
    estimatedArrivalDate: boolean,
    itemNumber: boolean,
    itemStatus: boolean,
    lineStatus: boolean,
    notes: boolean,
    orderDate: boolean,
    poDate: boolean,
    qty: boolean,
    qtyFulfilled: boolean,
    shipToName: boolean,
    shippedDate: boolean,
    supplierName: boolean,
    supplierOrderStatus: boolean,
    trackingNumbers: boolean,
    wildcard1: boolean,
    wildcard2: boolean,
}

const Account = () => {
    const [data, setData] = useState<UserInfo>();
    const [newUsername, setNewUsername] = useState<string>('');
    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);
    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');

    const [userOrderTrackingViewEnabledColumns, setUserOrderTrackingViewEnabledColumns] = useState<UserOrderTrackingViewEnabledColumnsModel>({
        id: 0,
        accountNumber: true,
        brandName: true,
        customerName: true,
        customerNumber: true,
        customerPoNumber: true,
        documentNumber: true,
        estimatedArrivalDate: true,
        itemNumber: true,
        itemStatus: true,
        lineStatus: true,
        notes: true,
        orderDate: true,
        poDate: true,
        qty: true,
        qtyFulfilled: true,
        shipToName: true,
        shippedDate: true,
        supplierName: true,
        supplierOrderStatus: true,
        trackingNumbers: true,
        wildcard1: true,
        wildcard2: true,
    });

    const [userOrderTrackingViewSettingsModel, setUserOrderTrackingViewSettingsModel] = useState<UserOrderTrackingViewSettingsModel>({
        id: 0,
        userId: 0,
        enabledColumns: userOrderTrackingViewEnabledColumns
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                var cookieUsername = getCookie('X-Username');
                const userInfoResponse = await axiosInstance.get('/api/accounts/user-info/' + cookieUsername);
                const userInfoData = userInfoResponse.data;

                setData(userInfoData);
                setEmail(userInfoData.email);
                setFirstName(userInfoData.firstName);
                setLastName(userInfoData.lastName);
                setNewUsername(cookieUsername);
                await fetchUserOrderTrackingViewSettings(userInfoData.userId);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchUserOrderTrackingViewSettings = async (userId) => {
            try {
                const response = await axiosInstance.get(`/api/accounts/order-tracking-settings?userId=${userId}`);
                const settingsData = response.data;
                if (settingsData.userId === 0) {
                    settingsData.userId = userId;
                }
                setUserOrderTrackingViewEnabledColumns(settingsData.enabledColumns);
                setUserOrderTrackingViewSettingsModel(settingsData);
            } catch (error) {
                console.error('Error fetching order tracking view settings:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setUserOrderTrackingViewSettingsModel(prevState => ({
            ...prevState,
            enabledColumns: userOrderTrackingViewEnabledColumns
        }));
    }, [userOrderTrackingViewEnabledColumns]);

    const formatDateTime = (dateString) => {
        if (!dateString) return '';
        const utcDate = new Date(dateString);
        return format(utcDate, 'PPpp');
    };

    const handleUsernameChange = (event) => {
        setNewUsername(event.target.value);
    };

    const handleSaveUsername = () => {
        var usernameChange = {
            UserID: data.userId,
            OldUsername: data.username,
            NewUsername: newUsername
        }
        axiosInstance.post('/api/accounts/update/username', usernameChange, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    axiosInstance.post('/auth/logout').then(response => {
                        window.location.href = window.location.origin + "/";
                    });
                } else {
                    console.log('failed');
                }
            })
            .catch(error => {
                console.error('Request failed:', error);
            });
    };

    const handleOldPassword = (event) => {
        setOldPassword(event.target.value);
    }
    const handleNewPassword = (event) => {
        setNewPassword(event.target.value);
    }
    const handleConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
    }
    const handleSavePassword = () => {
        const match = newPassword === confirmPassword;
        setPasswordsMatch(match);

        if (!match) {
            console.log("New password and confirm password do not match");
            return;
        }
        var passwordChange = {
            UserID: data.userId,
            Username: data.username,
            OldPassword: oldPassword,
            NewPassword: newPassword
        }
        axiosInstance.post('/api/accounts/update/password', passwordChange, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    axiosInstance.post('/auth/logout').then(response => {
                        window.location.href = window.location.origin + "/";
                    });
                } else {
                    console.log('failed');
                }
            })
            .catch(error => {
                console.error('Request failed:', error);
            });
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleSaveOrderTrackingViewColumns = () => {
        axiosInstance.post('/api/accounts/update/order-tracking-settings', userOrderTrackingViewSettingsModel, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    const settingsData = response.data;
                    setUserOrderTrackingViewEnabledColumns(settingsData.enabledColumns);
                    setUserOrderTrackingViewSettingsModel(settingsData);
                } else {
                    console.log('failed');
                }
            })
            .catch(error => {
                console.error('Request failed:', error);
            });
    };

    const handleColumnViewChange = (key) => {
        setUserOrderTrackingViewEnabledColumns((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const handleSaveUserInfo = () => {
        var userInfoChange = {
            UserID: data.userId,
            Email: email,
            FirstName: firstName,
            LastName: lastName
        }
        axiosInstance.post('/api/accounts/update/user-info', userInfoChange, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                setEmail(response.data.email);
                setFirstName(response.data.firstName);
                setLastName(response.data.lastName)
            })
            .catch(error => {
                console.error('Request failed:', error);
            });
    };

    return (
        <section className="py-24">
            <div className="container mx-auto px-4">
                <h1 className="text-3xl font-bold mb-8 text-center">User Information</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {data && (
                        <>
                            <Card>
                                <CardHeader>
                                    <CardTitle>User Id</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p>{data.userId}</p>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Username</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <div className="flex items-center justify-between">
                                        <p>{data.username}</p>
                                        <Dialog>
                                            <DialogTrigger asChild>
                                                <Button variant="outline" className="ml-4">Change Username</Button>
                                            </DialogTrigger>
                                            <DialogContent className="sm:max-w-[425px]">
                                                <DialogHeader>
                                                    <DialogTitle>Change Username</DialogTitle>
                                                    <DialogDescription>
                                                        Enter your new username below.
                                                    </DialogDescription>
                                                </DialogHeader>
                                                <div className="grid gap-4 py-4">
                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                        <Label htmlFor="username" className="text-right">
                                                            Username
                                                        </Label>
                                                        <Input
                                                            id="username"
                                                            value={newUsername}
                                                            onChange={handleUsernameChange}
                                                            className="col-span-3"
                                                        />
                                                    </div>
                                                </div>
                                                <DialogFooter>
                                                    <Button variant="outline" type="submit" onClick={handleSaveUsername} className="w-full">Save Changes</Button>
                                                </DialogFooter>
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog>
                                            <DialogTrigger asChild>
                                                <Button variant="outline" className="ml-4">Change Password</Button>
                                            </DialogTrigger>
                                            <DialogContent className="sm:max-w-[425px]">
                                                <DialogHeader>
                                                    <DialogTitle>Change Password</DialogTitle>
                                                </DialogHeader>
                                                <div className="grid gap-4 py-4">
                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                        <Label htmlFor="oldPassword" className="text-right">
                                                            Current Password
                                                        </Label>
                                                        <Input
                                                            id="oldPassword"
                                                            value={oldPassword}
                                                            onChange={handleOldPassword}
                                                            className="col-span-3"
                                                        />
                                                    </div>
                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                        <Label htmlFor="newPassword" className={`text-right ${!passwordsMatch && 'text-red-500'}`}>
                                                            New Password
                                                        </Label>
                                                        <Input
                                                            id="newPassword"
                                                            value={newPassword}
                                                            onChange={handleNewPassword}
                                                            className="col-span-3"
                                                        />
                                                    </div>
                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                        <Label htmlFor="confirmPassword" className={`text-right ${!passwordsMatch && 'text-red-500'}`}>
                                                            Confirm Password
                                                        </Label>
                                                        <Input
                                                            id="confirmPassword"
                                                            value={confirmPassword}
                                                            onChange={handleConfirmPassword}
                                                            className="col-span-3"
                                                        />
                                                    </div>
                                                </div>
                                                <DialogFooter>
                                                    <Button variant="outline" type="submit" onClick={handleSavePassword} className="w-full">Save Password</Button>
                                                </DialogFooter>
                                            </DialogContent>
                                        </Dialog>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Email</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <div className="flex items-center justify-between">
                                        <Input
                                            id="email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            className="w-full"
                                        />
                                        <Button variant="outline" onClick={handleSaveUserInfo} className="ml-4">Save</Button>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle>First Name</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <div className="flex items-center justify-between">
                                        <Input
                                            id="firstName"
                                            value={firstName}
                                            onChange={handleFirstNameChange}
                                            className="w-full"
                                        />
                                        <Button variant="outline" onClick={handleSaveUserInfo} className="ml-4">Save</Button>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Last Name</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <div className="flex items-center justify-between">
                                        <Input
                                            id="lastName"
                                            value={lastName}
                                            onChange={handleLastNameChange}
                                            className="w-full"
                                        />
                                        <Button variant="outline" onClick={handleSaveUserInfo} className="ml-4">Save</Button>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Order Tracking Page View Columns</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <div className="flex items-center justify-between"><Dialog>
                                        <DialogTrigger asChild>
                                            <Button variant="outline" className="ml-4">Select Columns</Button>
                                        </DialogTrigger>
                                        <DialogContent className="sm:max-w-[900px]">
                                            <DialogHeader>
                                                <DialogTitle>Order Tracking Page View Columns</DialogTitle>
                                                <DialogDescription>
                                                    Select which columns you want on your default view
                                                </DialogDescription>
                                            </DialogHeader>
                                            <div className="grid gap-4 py-4 grid-cols-3">
                                                {userOrderTrackingViewEnabledColumns && Object.keys(userOrderTrackingViewEnabledColumns).filter(key => key !== 'id' && key !== 'userOrderTrackingViewSettingsId').map(key => (
                                                    <div className="grid grid-cols-2 items-center gap-4" key={key}>
                                                        <Label htmlFor={key} className="text-right">
                                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                                        </Label>
                                                        <Switch
                                                            id={key}
                                                            name={key}
                                                            checked={userOrderTrackingViewEnabledColumns[key]}
                                                            onCheckedChange={() => handleColumnViewChange(key)}
                                                            className="col-span-1"
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            <DialogFooter>
                                                <DialogClose asChild>
                                                    <Button variant="outline" type="submit" onClick={handleSaveOrderTrackingViewColumns} className="w-full">Save Changes</Button>
                                                </DialogClose>
                                            </DialogFooter>
                                        </DialogContent>
                                    </Dialog></div>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Last Login</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p>{formatDateTime(data.lastLogin)}</p>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Created On</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p>{formatDateTime(data.createdOn)}</p>
                                </CardContent>
                            </Card>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Account;
