import React, { useState, useEffect } from 'react';
import { DataTable } from '../../../lib/ui/data-table';
import { Source, columns as originalColumns } from './columns';
import { Button } from '../../../lib/ui/button';
import axiosInstance from '../../../../axiosInterceptor';
import { MoreHorizontal } from 'lucide-react';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger
} from '../../../lib/ui/dropdown-menu';
import DeleteDialog from '../../../lib/ui/delete-dialog';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../../../lib/ui/dialog';
import { Input } from '../../../lib/ui/input';
import { Textarea } from '../../../lib/ui/textarea';

const EmailSources = () => {
    const [data, setData] = useState<Source[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [sourceToDelete, setSourceToDelete] = useState<Source | null>(null);
    const [isTestEmailDialogOpen, setIsTestEmailDialogOpen] = useState(false);
    const [selectedSource, setSelectedSource] = useState<Source | null>(null);
    const [toEmail, setToEmail] = useState('');
    const [subjectLine, setSubjectLine] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [attachment, setAttachment] = useState<File | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/api/email-sources/emails');
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleDelete = async () => {
        if (!sourceToDelete) return;

        try {
            await axiosInstance.post(`api/email-sources/delete/${sourceToDelete.id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            setData(prevData => prevData.filter(source => source.id !== sourceToDelete.id));
            setIsDialogOpen(false);
        } catch (error) {
            console.error('Delete failed:', error);
        }
    };

    const handleSendTestEmail = async () => {
        if (!selectedSource || !toEmail || !subjectLine || !messageBody) {
            alert('Please fill in all required fields.');
            return;
        }

        if (attachment) {
            const reader = new FileReader();
            reader.readAsDataURL(attachment);
            reader.onload = async () => {
                const base64Data = (reader.result as string).split(',')[1];
                const fileData = {
                    FileName: attachment.name,
                    FileData: base64Data
                };

                const emailParams = {
                    ToEmail: toEmail,
                    SubjectLine: subjectLine,
                    MessageContent: messageBody,
                    FileData: fileData,
                    EmailSource: selectedSource,
                };

                try {
                    await axiosInstance.post('/api/email-sources/send-test-email', emailParams, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    setIsTestEmailDialogOpen(false);
                } catch (error) {
                    console.error('Send test email failed:', error);
                }
            };
        } else {
            const emailParams = {
                ToEmail: toEmail,
                SubjectLine: subjectLine,
                MessageContent: messageBody,
                FileData: null,
                EmailSource: selectedSource,
            };

            try {
                await axiosInstance.post('/api/email-sources/send-test-email', emailParams, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setIsTestEmailDialogOpen(false);
            } catch (error) {
                console.error('Send test email failed:', error);
            }
        }
    };

    const columns = originalColumns.map(column => {
        if (column.id === 'actions') {
            return {
                ...column,
                cell: ({ row }) => {
                    const source = row.original;
                    return (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant='ghost' className='h-8 w-8 p-0'>
                                    <span className='sr-only'>Open menu</span>
                                    <MoreHorizontal className='h-4 w-4' />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align='end'>
                                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                                <DropdownMenuItem onClick={() => window.location.href = `email-sources/edit/${source.id}`}>Edit</DropdownMenuItem>
                                <DropdownMenuItem onClick={() => {
                                    setSourceToDelete(source);
                                    setIsDialogOpen(true);
                                }}>Delete</DropdownMenuItem>
                                <DropdownMenuItem onClick={() => {
                                    setSelectedSource(source);
                                    setIsTestEmailDialogOpen(true);
                                }}>Send Test Email</DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    );
                }
            };
        }
        return column;
    });

    function addSource() {
        window.location.href = `email-sources/edit/0`;
    }

    return (
        <section className='py-24'>
            <div className='container'>
                <div className="flex items-center justify-between py-4">
                    <h1 className='mb-6 text-3xl font-bold'>Email Sources</h1>
                    <Button className='' variant="outline" onClick={addSource}>Add</Button>
                </div>
                <div className="rounded-md border">
                    <DataTable columns={columns} data={data} />
                </div>
            </div>

            {sourceToDelete && (
                <DeleteDialog
                    isOpen={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    onConfirm={handleDelete}
                    itemName={sourceToDelete.username}
                />
            )}

            {isTestEmailDialogOpen && selectedSource && (
                <Dialog open={isTestEmailDialogOpen} onOpenChange={() => setIsTestEmailDialogOpen(false)}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Send Test Email</DialogTitle>
                        </DialogHeader>
                        <div className='mb-4'>
                            <label className='block mb-1'>To Email</label>
                            <Input value={toEmail} onChange={(e) => setToEmail(e.target.value)} placeholder='Recipient email address' />
                        </div>
                        <div className='mb-4'>
                            <label className='block mb-1'>Subject Line</label>
                            <Input value={subjectLine} onChange={(e) => setSubjectLine(e.target.value)} placeholder='Email subject' />
                        </div>
                        <div className='mb-4'>
                            <label className='block mb-1'>Message Body</label>
                            <Textarea value={messageBody} onChange={(e) => setMessageBody(e.target.value)} placeholder='Email message' />
                        </div>
                        <div className='mb-4'>
                            <label className='block mb-1'>Attachment (optional)</label>
                            <Input type='file' onChange={(e) => setAttachment(e.target.files ? e.target.files[0] : null)} />
                        </div>
                        <DialogFooter>
                            <Button variant='outline' onClick={() => setIsTestEmailDialogOpen(false)}>Cancel</Button>
                            <Button variant='outline' className='ml-2' onClick={handleSendTestEmail}>Send</Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            )}
        </section>
    );
};

export default EmailSources;