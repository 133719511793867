import React, { useState, useEffect, FC } from 'react'
import LoginBox from "./components/pages/login";
import Home from "./components/pages/home";
import Account from "./components/pages/accountPage/account";
import OrderTrackingSources from "./components/pages/sources/orderTrackingSource/orderTrackingSource";
import EmailSources from "./components/pages/sources/emailSource/emailSource";
import EditEmailSources from "./components/pages/sources/emailSource/editEmailSource";
import InventorySources from "./components/pages/sources/inventorySource/inventorySource";
import EditInventorySources from "./components/pages/sources/inventorySource/editInventorySource";
import OrderTrackingOrders from "./components/pages/orderTrackingPage/orderTracking";
import axiosInstance from './axiosInterceptor';
import EditOrderTrackingSources from './components/pages/sources/orderTrackingSource/editOrderTrackingSource';
import Admin from './components/pages/adminPage/admin';
import ManageUsers from './components/pages/adminPage/manageUsersPage/manageUsers';
import EditUser from './components/pages/adminPage/manageUsersPage/editUsers';
import OrderTrackingDetails from './components/pages/orderTrackingPage/orderTrackingDetailsPage/orderTrackingDetails';
import OrderSubmissionSources from './components/pages/sources/orderSubmissionSource/orderSubmissionSource';
import EditOrderSubmissionSources from './components/pages/sources/orderSubmissionSource/editOrderSubmissionSource';
import { EditOrderSubmissionVendorSource } from './components/pages/sources/orderVendorSource/editOrderVendorSource';
import OrderSubmissionVendorSources from './components/pages/sources/orderVendorSource/orderVendorSource';

const RequireAuth: FC<{ children: React.ReactElement }> = ({ children }) => {

    // state to store whether the auth check has completed.
    const [loading, setLoading] = useState(true);

    // state to store whether the user is authenticated
    const [loggedIn, setLoggedIn] = useState(false);

    // state to store any error encounted while running the auth check.
    const [error, setError] = useState();

    useEffect(() => {
        // create async function to avoid the use of promise .then spam
        // this is just a preference coming from C# background prefer the 
        // async / await syntax.
        const checkLogin = async () => {
            try {
                // run the auth check.
                const response = await axiosInstance.get('/auth/allowed');

                // set whether the user is logged in.
                setLoggedIn(!!response?.data)

                // set that no error was encountered.
                setError(undefined);

            }
            // catch any error with the request.
            catch (err) {
                // set user to not logged in.
                setLoggedIn(false);

                // set the error to the caught exception.
                setError(err);

                // add another redirect call if you don't want to display an error when the request fails
                // e.g.: navigate("/" , {replace: true});
            }

            // request is finished set loading to false.
            setLoading(false);
        };

        checkLogin();
    }, [setLoading, setError, setLoggedIn])

    // This is the view while the auth check is being carried out:
    if (loading) {
        return (
            <>
                Put some nice loading screen here or something idk
            </>
        );
    }

    // This is the view if the user was not authenticated or there was an error (default is for server to throw a 415 or 401)
    // As the redirect is started as part of the useEffect this will
    // most likely not be displayed for any significant (or even preceivable)
    // amount of time.
    if (!loggedIn || error) {
        window.location.href = window.location.origin + "/";
    }

    // This is the view if the user was authenticated.
    // Example view below renders the child elements that have been provided i.e. <Home /> in your snippet.
    return (
        <>
            {children}
        </>
    )
};

const RequireAdmin: FC<{ children: React.ReactElement }> = ({ children }) => {

    const [loading, setLoading] = useState(true);

    const [isAdmin, setIsAdmin] = useState(false);

    const [error, setError] = useState();

    useEffect(() => {
        const checkIsAdmin = async () => {
            try {
                const response = await axiosInstance.get('/auth/user');

                setIsAdmin(response?.data?.role === 'Admin')

                setError(undefined);
            }
            catch (err) {
                setIsAdmin(false);
                setError(err);
            }
            setLoading(false);
        };

        checkIsAdmin();
    }, [setLoading, setError, setIsAdmin])

    if (loading) {
        return (
            <>
                Put some nice loading screen here or something idk
            </>
        );
    }
    if (!isAdmin || error) {
        window.location.href = window.location.origin + "/home";
    }

    return (
        <>
            {children}
        </>
    )
};

const AppRoutes = [
    {
        index: true,
        element: <LoginBox />
    },
    {
        path: '/home',
        element: <RequireAuth><Home /></RequireAuth>
    },
    {
        path: '/account',
        element: <RequireAuth><Account /></RequireAuth>
    },
    {
        path: '/admin',
        element: <RequireAuth><RequireAdmin><Admin /></RequireAdmin></RequireAuth>
    },
    {
        path: '/admin/manage/users',
        element: <RequireAuth><RequireAdmin><ManageUsers /></RequireAdmin></RequireAuth>
    },
    {
        path: '/admin/manage/users/edit/:userId',
        element: <RequireAuth><RequireAdmin><EditUser /></RequireAdmin></RequireAuth>
    },
    {
        path: '/order-tracking-sources',
        element: <RequireAuth><OrderTrackingSources /></RequireAuth>
    },
    {
        path: '/email-sources',
        element: <RequireAuth><EmailSources /></RequireAuth>
    },
    {
        path: '/email-sources/edit/:sourceId',
        element: <RequireAuth><EditEmailSources /></RequireAuth>
    },
    {
        path: '/order-tracking-sources/edit/:sourceId',
        element: <RequireAuth><EditOrderTrackingSources /></RequireAuth>
    },
    {
        path: '/inventory-sources',
        element: <RequireAuth><InventorySources /></RequireAuth>
    },
    {
        path: '/inventory-sources/edit/:sourceId',
        element: <RequireAuth><EditInventorySources /></RequireAuth>
    },
    {
        path: '/order-tracking',
        element: <RequireAuth><OrderTrackingOrders /></RequireAuth>
    },
    {
        path: '/order-tracking/order/:orderId',
        element: <RequireAuth><OrderTrackingDetails /></RequireAuth>
    },
    {
        path: '/order-submission/sources',
        element: <RequireAuth><OrderSubmissionSources /></RequireAuth>
    },
    {
        path: '/order-submission/sources/edit/:sourceId',
        element: <RequireAuth><EditOrderSubmissionSources /></RequireAuth>
    },
    {
        path: '/order-submission/vendor',
        element: <RequireAuth><OrderSubmissionVendorSources /></RequireAuth>
    },
    {
        path: '/order-submission/vendor/edit/:vendorId',
        element: <RequireAuth><EditOrderSubmissionVendorSource /></RequireAuth>
    }
];

export default AppRoutes;
