import React, { Component, ReactNode } from 'react';

interface ContainerProps {
    children: ReactNode;
}
export class Layout extends Component<ContainerProps> {
  static displayName = Layout.name;
  //was wrapped in a container before
  render() {
    return (
      <div>
        <>
          {this.props.children}
        </>
      </div>
    );
  }
}
