import React, { useEffect, useState } from 'react';
import { Button } from "../lib/ui/button"
import { Input } from "../lib/ui/input"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../lib/ui/card';
import { Label } from '../lib/ui/label';
import axiosInstance from '../../axiosInterceptor';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../lib/ui/dialog';

const LoginBox = () => {
    const redirectHome = () => {
        window.location.replace(window.location.origin + "/home");
    }

    //const [data, setData] = useState(null);

    const handleLogin = (login) => {
        //setData(login);

        const creds = {
            username: login.username,
            password: login.password
        }

        axiosInstance.post('auth/login', creds, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    redirectHome();
                } else {
                    console.log('failed');
                }
            })
            .catch(error => {
                console.error('Request failed:', error);
            });
    }

    return (
        <div className="relative mt-60 w-1/3 mx-auto min-w-fit">
            <LoginForm onLogin={handleLogin} />
        </div>
    );
}

const LoginForm = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordResetUsername, setPasswordResetUsername] = useState('');
    const [valuesAreFilled, setValuesAreFilled] = useState(false);

    const handlePasswordResetChange = (e) => {
        setPasswordResetUsername(e.target.value);
    }

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        const trimmedUsername = username.trim();
        const trimmedPassword = password.trim();
        if (!trimmedUsername || !trimmedPassword) {
            return;
        }
        onLogin({ username: trimmedUsername, password: trimmedPassword });
        setUsername('');
        setPassword('');
    }

    const handleForgotPasswordSubmit = () => {
        axiosInstance.post('auth/reset-password', passwordResetUsername, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Enter' && valuesAreFilled) {
                e.preventDefault(); // Prevent default action to avoid potential issues
                handleLoginSubmit(e);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [valuesAreFilled]);

    useEffect(() => {
        const valuesAreFilled = () => {
            if (username && password) {
                setValuesAreFilled(true);
            }
        };

        valuesAreFilled();
    }, [username, password]);

    return (
        <Card>
            <CardHeader className="space-y-1">
                <CardTitle className="text-2xl">Login</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-4">
                <div className="grid gap-2">
                    <Label htmlFor="email">Username</Label>
                    <Input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={handleUsernameChange}
                    />
                </div>
                <div className="grid gap-2">
                    <Label htmlFor="password">Password</Label>
                    <Input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>
            </CardContent>
            <CardFooter>
                <Button className="w-full mt-2" variant="outline" onClick={handleLoginSubmit}>Login</Button>
            </CardFooter>
            <CardFooter>
                <Dialog>
                    <DialogTrigger asChild>
                        <Button className="w-full" variant="ghost">Forgot Password</Button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[425px]">
                        <DialogHeader>
                            <DialogTitle>Reset Password</DialogTitle>
                            <DialogDescription>
                                Enter your username below to recieve a password reset email.
                            </DialogDescription>
                        </DialogHeader>
                        <div className="grid gap-4 py-4">
                            <div className="grid grid-cols-4 items-center gap-4">
                                <Label htmlFor="passwordResetUsername" className="text-right">
                                    Username
                                </Label>
                                <Input
                                    id="passwordResetUsername"
                                    value={passwordResetUsername}
                                    onChange={handlePasswordResetChange}
                                    className="col-span-3"
                                />
                            </div>
                        </div>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant="outline" type="submit" onClick={handleForgotPasswordSubmit} className="w-full">Reset Password</Button>
                            </DialogClose>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </CardFooter>
        </Card>
    );
}

export default LoginBox;
