import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../../axiosInterceptor';
import { Button } from '../../../lib/ui/button';
import { DataTable } from '../../../lib/ui/data-table';
import { User, columns as originalColumns } from './columns';
import DeleteDialog from '../../../lib/ui/delete-dialog';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from '../../../lib/ui/dropdown-menu';
import { MoreHorizontal } from 'lucide-react';

const ManageUsers = () => {
    const [data, setData] = useState<User[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState<User | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('api/accounts/list');
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleDelete = async () => {
        if (!userToDelete) return;

        try {
            await axiosInstance.post(`api/accounts/delete/${userToDelete.userId}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            setData(prevData => prevData.filter(user => user.userId !== userToDelete.userId));
            setIsDialogOpen(false);
        } catch (error) {
            console.error('Delete failed:', error);
        }
    };

    const columns = originalColumns.map(column => {
        if (column.id === 'actions') {
            return {
                ...column,
                cell: ({ row }) => {
                    const user = row.original;
                    return (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant='ghost' className='h-8 w-8 p-0'>
                                    <span className='sr-only'>Open menu</span>
                                    <MoreHorizontal className='h-4 w-4' />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align='end'>
                                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                                <DropdownMenuItem onClick={() => window.location.href = `admin/manage/users/edit/${user.userId}`}>Edit</DropdownMenuItem>
                                <DropdownMenuItem onClick={() => {
                                    setUserToDelete(user);
                                    setIsDialogOpen(true);
                                }}>Delete</DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    );
                }
            };
        }
        return column;
    });

    function addNewUser() {
        window.location.href = `admin/manage/users/edit/0`;
    }

    return (
        <section className='py-24'>
            <div className='container'>
                <div className="flex items-center justify-between py-4">
                    <h1 className='mb-4 text-3xl font-bold'>Users</h1>
                    <Button className='mb-4' variant="outline" onClick={addNewUser}>Add</Button>
                </div>
                <div className="rounded-md border">
                    <DataTable columns={columns} data={data} />
                </div>
            </div>

            {userToDelete && (
                <DeleteDialog
                    isOpen={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    onConfirm={handleDelete}
                    itemName={userToDelete.username}
                />
            )}
        </section>
    );
};

export default ManageUsers;
