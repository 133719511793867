import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInterceptor';

interface UpdateStatsModel {
    orderTrackingSourceName: string;
    ordersUpdated: number;
    ordersDeleted: number;
}

const Home = () => {
    const [stats, setStats] = useState<UpdateStatsModel[]>([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            axiosInstance.get('/api/order-tracking/list/update-stats').then(response => {
                setStats(response.data);
            });
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div className="container mx-auto py-8">
            <header>
                <h1 className="text-2xl font-bold">Welcome to the Home Page</h1>
            </header>
            <div className="text-left my-8">
                <p>Here are the stats for the past 24 hours:</p>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border">Source Name</th>
                            <th className="px-4 py-2 border">Orders Updated</th>
                            <th className="px-4 py-2 border">Orders Deleted</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stats.map((stat, index) => (
                            <tr key={index}>
                                <td className="px-4 py-2 border">{stat.orderTrackingSourceName}</td>
                                <td className="px-4 py-2 border">{stat.ordersUpdated}</td>
                                <td className="px-4 py-2 border">{stat.ordersDeleted}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Home;
